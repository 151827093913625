@mixin scrollbar {
  &::-webkit-scrollbar {
    width: rem(10);

    @include mediaBigDesktop {
      width: big(10);
    }
  }

  &::-webkit-scrollbar-track {
    background: var(--bg-white);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--elements-gray-secondary);
    border-radius: rem(999);
    border: 3px solid var(--bg-white);

    @include mediaBigDesktop {
      border-radius: big(999);
      border-width: big(3);
    }
  }
}

@mixin scrollbarHorizontal {
  &::-webkit-scrollbar {
    height: rem(10);

    @include mediaBigDesktop {
      height: big(10);
    }
  }

  &::-webkit-scrollbar-track {
    background-color: var(--bg-white);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--elements-gray-secondary);
    border-radius: rem(999);
    border: 3px solid var(--bg-white);

    @include mediaBigDesktop {
      border-radius: big(999);
      border-width: big(3);
    }
  }
}

@mixin scrollbarDelete {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}
