@import '../../mixins';

.modal-cart {
  &__title {
    margin-bottom: rem(37);

    width: 100%;
    display: flex;

    @include mediaBigDesktop {
      margin-bottom: big(37);
    }

    @include mediaLaptop {
      margin-bottom: rem(30);

      font-size: rem(18);
    }

    @include mediaTablet {
      font-size: rem(20);
    }

    @include mediaMobile {
      margin-bottom: rem(25);

      font-size: rem(18);
    }
  }

  &__button {

    width: 100%;
    display: flex;
    flex-direction: row;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaLaptop {
      margin-bottom: rem(15);
      padding-bottom: rem(15);

      gap: rem(15);
    }

    @include mediaTablet {
      gap: rem(20);
    }

    @include mediaMobile {
      flex-direction: column;
      gap: rem(10);
    }

    & .button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }
  }

  &__product-list {
    position: relative;
    left: rem(-40);
    padding: 0 rem(40);

    width: calc(100% + (#{rem(40)} * 2));
    display: flex;
    flex-direction: row;
    gap: rem(20);

    overflow: auto;

    @include mediaBigDesktop {
      left: big(-40);
      padding: 0 big(40);
      width: calc(100% + (#{big(40)} * 2));
      gap: big(20);
    }

    @include mediaLaptop {
      left: rem(-30);
      padding: 0 rem(30);

      width: calc(100% + (#{rem(30)} * 2));
      gap: rem(15);
    }

    @include mediaTablet {
      gap: rem(20);
    }

    @include mediaMobile {
      left: rem(-15);
      padding: 0 rem(15);

      width: calc(100% + (#{rem(15)} * 2));
      gap: rem(15);
    }

    @include scrollbarHorizontal();

    & .product-card {
      width: rem(233);
      flex-shrink: 0;

      @include mediaBigDesktop {
        width: big(233);
      }

      @include mediaLaptop {
        width: rem(199);
      }

      @include mediaTablet {
        width: rem(209);
      }

      @include mediaMobile {
        width: rem(239);
      }


      & form {
        padding: rem(10) rem(10) rem(15);

        gap: rem(10);

        @include mediaBigDesktop {
          padding: big(10) big(10) big(15);
          gap: big(10);
        }
      }

      &__name {
        &::before {
          content: unset;
        }

        @include mediaTablet {
          height: rem(42);
          -webkit-line-clamp: 2;
        }
      }

      &__bottom {
        padding: 0 rem(5);

        @include mediaBigDesktop {
          padding: 0 big(5);
        }

        @include mediaLaptop {
          gap: rem(10);
        }

        @include mediaTablet {
          gap: rem(15);
        }
      }

      &__top {
        height: rem(150);
        gap: rem(12);

        @include mediaBigDesktop {
          height: big(150);
          gap: big(12);
        }

        @include mediaLaptop {
          height: rem(110);
        }

        @include mediaTablet {
          height: rem(120);
        }

        @include mediaMobile {
          height: rem(140);
        }
      }

      &__tag-list {
        gap: rem(2);

        @include mediaBigDesktop {
          gap: big(2);
        }

        @include mediaLaptop {
          gap: rem(5);
        }

        & .tag {
          padding: rem(2) rem(8);

          font-size: rem(14);

          @include mediaBigDesktop {
            padding: big(2) big(8);

            font-size: big(14);
          }

          @include mediaLaptop {
            padding: rem(2) rem(6);

            font-size: rem(12);
          }
        }
      }

      &__menu {
        flex-direction: row;
        flex-wrap: nowrap;

        & .button {
          padding: rem(3);

          @include mediaBigDesktop {
            padding: big(3);
          }
        }
      }

      & .price {
        &__old {
          @include mediaTablet {
            font-size: rem(14);
          }
        }
      }
    }
  }

  &__additionally {
    margin-top: rem(20);
    padding-top: rem(20);

    display: flex;
    flex-direction: column;
    gap: rem(20);

    border-top: solid 1px var(--stroke-dark);

    @include mediaBigDesktop {
      margin-top: big(20);
      padding-top: big(20);

      gap: big(20);

      border-top-width: big(1);
    }

    @include mediaLaptop {
      margin-top: rem(20);
      padding-top: rem(20);

      gap: rem(15);
    }

    @include mediaMobile {
      margin-top: rem(15);
      padding-top: rem(15);
    }
  }
}
